import { RouterProvider } from "react-router-dom";
import MFEContainer from "./containers/MFEContainer";
import { UserInfo } from "./types/UserInfo";
import { Router } from "@remix-run/router";
import UserContext from "./contexts/UserContext";

export interface Props {
  language: string;
  userInfo: UserInfo;
  router: Router;
}

function App({ language, userInfo, router }: Props) {
  return (
    <MFEContainer language={language}>
      <UserContext userInfo={userInfo}>
        <RouterProvider router={router} />
      </UserContext>
    </MFEContainer>
  );
}

export default App;

import { Link } from "react-router-dom";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import Translate from "../../components/Translation/Translate";

const TaxForm = () => {
  return (
    <div>
      <h1>
        <Translate translationKey={TranslationKeys.TAX_FORMS_HEADER_TITLE} />
      </h1>
      <Link to="/tax-center">Go to Tax Center</Link>
    </div>
  );
};

export default TaxForm;

import { Link } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import Translate from "../../components/Translation/Translate";

const Dashboard = () => {
  const userInfo = useUserInfo();
  return (
    <div>
      <h1>
        <Translate translationKey={TranslationKeys.HOME_HEADER_TITLE} />
      </h1>
      <div>
        <Link to="/tax-center/tax-form">Go to Tax Form</Link>
      </div>
      <p>{userInfo.fullName || userInfo.entityName}</p>
    </div>
  );
};

export default Dashboard;

import { Navigate } from "react-router";
import Dashboard from "./views/Dashboard";
import TaxForm from "./views/TaxForm";

export const routes = [
  {
    path: "/",
    element: <Navigate to="/tax-center" replace />,
  },
  {
    path: "/tax-center",
    children: [
      { path: "", element: <Dashboard /> },
      { path: "tax-form", element: <TaxForm /> },
    ],
  },
];

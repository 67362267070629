import { useContext } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import { TranslationKey } from "../../contexts/I18nContext/translationKeys";

interface TranslateProps {
  translationKey: TranslationKey;
  replacements?: Record<string, string | number>;
}

export default function Translate({
  translationKey,
  replacements,
}: TranslateProps): JSX.Element {
  const { getTranslation } = useContext(I18nContext);

  return <>{getTranslation(translationKey, replacements)}</>;
}

import { useContext } from "react";
import { UserInfo } from "../types/UserInfo";
import { UserContext } from "../contexts/UserContext";

const useUserInfo: () => UserInfo = () => {
  const { userInfo } = useContext(UserContext);
  return userInfo;
};

export default useUserInfo;
